@font-face {
  font-family: "League Spartan";
  src: url("../public/fonts/LeagueSpartan-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #fff;
  font-family: "League Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.blackSpinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid black;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.hover-show-time {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.comment-block:hover .hover-show-time {
  opacity: 1;
  height: 16px; /* Adjust this to fit your actual text height */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hover-shadow {
  transition: box-shadow 0.15s ease-in-out;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hover-bg {
  transition: background-color 0.15s ease-in-out;
}

.hover-bg:hover {
  background-color: #F5F5F5; /* Or whatever dark gray you want */
}

.hover-bg-light {
  transition: background-color 0.35s ease-in-out;
}

.hover-bg-light:hover {
  background-color: #fafafa; /* Or whatever dark gray you want */
}

.hover-bg-blue {
  transition: background-color 0.15s ease-in-out;
}

.hover-bg-blue:hover {
  background-color: #0767c1; /* Or whatever dark gray you want */
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-2deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(2deg); }
  100% { transform: rotate(0deg); }
}

.wiggle {
  animation: wiggle 0.2s ease-in-out infinite;
}

@keyframes wiggle-light {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-0.4deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(0.4deg); }
  100% { transform: rotate(0deg); }
}

.wiggle-light {
  animation: wiggle-light 0.2s ease-in-out infinite;
}

@keyframes addingComment {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #0D76DB;
  }
  100% {
    border-color: transparent;
  }
}

.adding-comment {
  border: 3px solid #0D76DB;
  animation: addingComment 1s infinite;
}
 
.icon-cursor {
  display: none;
  position: absolute;
  pointer-events: none; /* This ensures the icon doesn't interfere with clicks */
  z-index: 9999; /* Keep the icon on top */
}

.target-div:hover .icon-cursor {
  display: inline;
}

/* In your global.css or equivalent file */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* color of the track. It could also be transparent */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-primary); /* your primary color */
  border-radius: 9999px; /* roundness of the scrollbar */
  border: 3px solid var(--color-primary); /* Creates padding around scroll */
}
 
.nav-arrow {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.your-container:hover .nav-arrow {
  opacity: 1;
}

.cursor-comment {
  cursor: url("./icons/comment-cursor.png"), auto;
}

.reply-trash {
  display: none;
}

.reply:hover .reply-trash {
  display:inline;
}

