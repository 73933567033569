@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Semi-black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it sits on top */
  }
  
  .modal-content {
    z-index: 10000; /* Make sure it sits on top of the backdrop */
  }

  .upload-toast {
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: 250px;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  
  .upload-toast.enter {
    opacity: 1;
    transform: translateY(0);
  }
  
  .upload-toast.exit {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;
  }
  
  .upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .upload-title {
    font-size: 14px;
    font-weight: bold;
  }
  
  .upload-close {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .upload-body {
    margin-top: 8px;
  }
  
  .upload-progress {
    margin-bottom: 4px;
  }
  
  .upload-filename {
    font-size: 12px;
    color: #666;
  }
  
  .upload-progress-bar {
    background: #eee;
    height: 4px;
    border-radius: 2px;
  }
  
  .upload-progress-fill {
    background: #007bff;
    height: 4px;
    border-radius: 2px;
  }
  
  .upload-footer {
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
  }


  /* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #0D76DB;
}

input:checked ~ .block {
  background-color: #0D76DB;
}
